/*
# **********************************************************************
#
#  (c) 2020 Tobias Fleckenstein <tfleckenstein@mp-group.net>, mp group GmbH
#
# **********************************************************************
# date/time    : 16.04.2020
# project      : Decors Resopal
# developer    : Tobias Fleckenstein
#
#   .-------------'```'----....,,__                        _,
#   |                               `'`'`'`'-.,.__        .'(
#   |                                             `'--._.'   )
#   |                                                   `'-.<
#   \               .-'`'-.                            -.    `\
#    \               -.o_.     _                     _,-'`\    |
#     ``````''--.._.-=-._    .'  \            _,,--'`      `-._(
#       (^^^^^^^^`___    '-. |    \  __,,..--'                 `
#        `````````   `'--..___\    |`
#                              `-.,'
# **********************************************************************
*/

/* Sort Icon neusetzen */
.bootstrap-table .fixed-table-container .table thead th .sortable {
	/*background-position: left;
	padding-left: 20px !important;
	padding-right:0 !important;*/
	background: none;
	padding-right:0;
}

.bootstrap-table .fixed-table-container .table thead th .sortable {
	display: inline;
}
.bootstrap-table .fixed-table-container .table thead th .sortable::after{
	content: "\f0dc";
	font-family:"Font Awesome 5 Pro";
	font-weight:900;
	color: $grey-lighten45;
	padding-left: 5px;
	-moz-osx-font-smoothing:grayscale;
	-webkit-font-smoothing:antialiased;
	display:inline-block;
	font-style:normal;
	font-variant:normal;
	text-rendering:auto;
	line-height:1;
}
.bootstrap-table .fixed-table-container .table thead th .sortable.asc::after{
	content: "\f0de";
}
.bootstrap-table .fixed-table-container .table thead th .sortable.desc::after{
	content: "\f0dd";
}


.table-borderless{
	border: none;
	thead{
		border-bottom: 2px solid $border-color;
		th{
			font-size: 1.1rem;
		}
	}
}
.tableHeaderBtn{
	position: absolute;
	top: 47px;
}
.tableHeaderBtn.btn-new-decor{
	position: relative;
	top: 0;
	margin-top: 5px;
}

.middleTextInTd th,
.middleTextInTd td {
	vertical-align: middle;;
}
/* Tabellen */
.table th{
	border-top: none;
}
.trHover:hover td{
	background-color: $grey-lighten45 !important;
}
.nowrapTd td{
	white-space: nowrap;
}

.scrollableTable {
	overflow-x: auto;
	overflow-y: auto;
	height: 70vh;
	border-bottom: 1px solid $grey-lighten45;
}
table.lastColFixed thead tr th.actionCol,
table.lastColFixed tbody tr td.actionCol{
	background-color: rgba($white, 0.95);
	position: sticky;
	right: -11px;
	background-clip: padding-box;
	width: 90px;
	text-align: right;
}
table.lastColFixed tbody tr td.actionCol a,
table.lastColFixed tbody tr td.actionCol button,
table.lastColFixed tbody tr td.actionCol form{
	display: inline-block;
}

/*
.tableFixHead {
	overflow-y: auto;
}

.tableFixHead thead th {
	background-color: #fff;
}
*/

.tableFixed tbody {
	display:block;
	height:70vh;
	overflow:auto;
}
.tableFixed thead,
.tableFixed tbody tr {
	display:table;
	width:100%;
	table-layout:fixed;
}
.tableFixed thead,
.tableFixed tbody tr {
	display:table;
	width:100%;
	table-layout:fixed;
}
.tableFixed thead tr,
.tableFixed tbody tr{
	td,
	th{
	 white-space: nowrap;
	 text-overflow: ellipsis;
			overflow: hidden;
		&.firstCol{
			width: 80px;
			overflow: initial;
		}
		&.nrCol{
			width: 115px;
			overflow: initial;
		}
		&.idCol{
			width: 55px;
			overflow: initial;
		}
		&.entryDateCol{
			width: 140px;
			overflow: initial;
		}
		&.actionCol{
			width: 90px;
			overflow: initial;
		}
	}
}

@media only screen and (max-width: 991px) {
	table.responsiveTable,
	.responsiveTable thead,
	.responsiveTable tbody,
	.responsiveTable th,
	.responsiveTable td,
	.responsiveTable tr {
		display: block;
	}
	.responsiveTable{
		 thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		tr {
			border: 1px solid $grey-lighten45;
			margin-top: 10px;
			td {
				border: none;
				border-bottom: 1px solid $grey-lighten45;
				background: $white;
				width: auto !important; /* fix für fest gesetzte breiten*/
				text-align: left !important;
				position: relative;
				padding-left: 50%;

				&:before {
					content: attr(data-label);
					position: absolute;
					top: 6px;
					left: 6px;
					width: 30%;
					padding-right: 10px;
					white-space: nowrap;
				}
			}
		}
	}
	.responsiveTable .decorCoverBg {
		height: 80px !important;
		width: 80px !important;
	}
	/* Kein Hover für Cardansicht */
	.responsiveTable .trHover:hover td,
	.responsiveTable .table-striped tbody tr:nth-of-type(2n+1) {
		background-color: transparent !important;
	}
	/* grauer HIntergrund für Bilder
	.firstCol,
	.trHover:hover .firstCol{
		background: $grey-lighten45 !important;
	}
	*/
}