/*
# **********************************************************************
#
#  (c) 2020 Tobias Fleckenstein <tfleckenstein@mp-group.net>, mp group GmbH
#
# **********************************************************************
# date/time    : 20.04.2020
# project      : Resopal
# developer    : Tobias Fleckenstein
#
#   .-------------'```'----....,,__                        _,
#   |                               `'`'`'`'-.,.__        .'(
#   |                                             `'--._.'   )
#   |                                                   `'-.<
#   \               .-'`'-.                            -.    `\
#    \               -.o_.     _                     _,-'`\    |
#     ``````''--.._.-=-._    .'  \            _,,--'`      `-._(
#       (^^^^^^^^`___    '-. |    \  __,,..--'                 `
#        `````````   `'--..___\    |`
#                              `-.,'
# **********************************************************************
*/


$theme-colors: (
  "search": $red,
	"login":$red,
	"upload":$red,
	"cloud-upload":$green,
	"back-to-overview":$red
);

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

/* Erstelle eigenen Buttons */
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-outline-secondary{
	border-color: $secondary-color;
}
.btn-secondary {
	background-color: $secondary-color;
	border-color: $secondary-color;
}
.input-group-text {
	color: $white;
	background-color: $red;
	border: 1px solid darken( $red, 10% );;
}

@media(max-width:991px){
	.btn-grow-to-block{
		width: 100%;
		display: block;
	}
}