/*
# **********************************************************************
#
#  (c) 2020 Tobias Fleckenstein <tfleckenstein@mp-group.net>, mp group GmbH
#
# **********************************************************************
# date/time    : 02.01.2020
# project      : Resopal Decors
# developer    : Tobias Fleckenstein
#
#   .-------------'```'----....,,__                        _,
#   |                               `'`'`'`'-.,.__        .'(
#   |                                             `'--._.'   )
#   |                                                   `'-.<
#   \               .-'`'-.                            -.    `\
#    \               -.o_.     _                     _,-'`\    |
#     ``````''--.._.-=-._    .'  \            _,,--'`      `-._(
#       (^^^^^^^^`___    '-. |    \  __,,..--'                 `
#        `````````   `'--..___\    |`
#                              `-.,'
# **********************************************************************
*/

@import '_variables';
@import '~bootstrap/scss/bootstrap';
 /*@import '~@fancyapps/fancybox/dist/jquery.fancybox';*/

@import "~bootstrap-table/dist/bootstrap-table.min.css";
@import "table";
@import"btn";


/* atlassian Vorgangssammler*/
.atlwdg-trigger.atlwdg-SUBTLE{
  z-index: 50000;
}
.sf-minitoolbar {
  left: 0 !important;
  right: auto !important;
  border-radius: 0 !important;
  opacity: .3;
}

body{
  font-family: $font-family-sans-serif;
  padding-top: $navbar-top-height
}
body.adminOnline{
  padding-top: $navbar-top-height + $siteHeaderHeight;
}

/* Misc */
.form-control::placeholder,
.decorFilter .form-group .bs-placeholder .filter-option-inner-inner{
  color: $placeholder;
}

.marginRight15{
  margin-right: 15px;
}

html.scan {
  height:93%;
}

.scan {
  .img-fluid {
    max-width:inherit;
    width:100%;
  }
}

.bottomCentered {
  width:202px;
  position: absolute;
  bottom: 20px;
  display:block;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
}

.scanToolBorder{
  padding:15px;
  height:100%;
}

#result.scan {
  margin-top:10px;
}

.red {
  color: $red;
}

/* BOF Header */
.logoImage{
  width: auto;
  max-height: 30px;
  margin-right: 15px;
  vertical-align: middle;
}
.logoExtension{
  color: $font-default;
  font-size: 1.35rem;
  position: absolute;
  bottom: -7px;
}
.navbar{
  min-height: $navbar-top-height;
}
.bg-light {
  background-color: transparent !important;
}

/* custom ceckbox, Radio usw */
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $red;
  background-color: $red;
}
/* EOF Header */
/* BOF Seitenheader*/
.adminOnline .siteHeaderContainer{
  top: $navbar-top-height;
}
.siteHeaderContainer{
  min-height: $siteHeaderHeight;
  background: $white;
  padding-top: 8px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0px 3px 2px 0px rgba(98,98,98,0.5);
  -moz-box-shadow: 0px 3px 2px 0px rgba(98,98,98,0.5);
  box-shadow: 0px 3px 2px 0px rgba(98,98,98,0.5);
}
/* EOF Seitenheader */

/* BOF Adminbar*/
.adminNavBar{
  background: $adminNavbarBg;
  z-index: 1031;
  justify-content: flex-end;
  nav{
    min-height: auto;
    padding-top: 0;
    padding-bottom: 0;
    .nav-item{
      border-top: 2px solid $adminNavbarBg;
      border-right: 1px solid $white;
      &:last-child{
        border-right: none
      }
      &:hover {
        background: $white;
        border-top: 2px solid $red;
      }
      &.active {
        background: $white;
        border-top: 2px solid $red;
      }
      .nav-link{
        color: $white;
        font-size: .8rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-right: 0.8rem;
        padding-left: 0.8rem;
      }
    }
  }
}
@media(max-width:992px){
  .adminNavBar{
    nav{
      justify-content: right;
      .navbar-toggler{
        font-size: 1.1rem;
        color: $white !important;
        border-color: $white;
        background:$red;
        margin: .3rem 0;
        .navbar-toggler-icon {
          width: 1.2em;
          height: 1.2em;
        }
      }
      .nav-item {
        border-top: 2px solid #d5d5d5;
        border-right: none;
        border-bottom: 1px solid #ffffff;
        &:last-child{
          border-bottom: none;
        }
      }
    }
  }
}
/* EOF Adminbar*/

.footerBar{
  font-size: .75rem;
  padding: 5px 15px;
  text-align: right;
  background: rgba(255,255,255,.95);
}

/*  Form Styles */
.form-control:focus {
  border-color: $border-color;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(227,0,27,0.7);
  -moz-box-shadow: 0px 0px 3px 0px rgba(227,0,27,0.7);
  box-shadow: 0px 0px 3px 0px rgba(227,0,27,0.7);
}
.custom-select{
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* BOF Misc */
.simulateLink{
  cursor: pointer;
}
/* EOF Misc*/

/* BOF Listview */
.clickableRow,
.simulateLink{
  cursor: pointer;
}
.clickableRow:hover td,
.simulateLink:hover td{
  color:$red;
}
.decorCoverBg{
  height: 50px;
  width: 50px;
  background-size: cover;
  border: 2px solid $white;
}
.simulateLink td{
  vertical-align: middle;
}
/* EOF listview */

/* BOF Detailview */
.decorDetail{

  .tab-content{
    border: 1px solid $nav-tabs-border-color;
    margin-top: -1px;
    .decorDetailImageBorder{

      display: flex;
      justify-content: center;
      /*height: 56vh;
      height: calc(100vh - 400px);*/
      padding: 25px;

    }
  }
}

@media(max-width:991px){
  dt{
    display: inline-block;
  }
  dd{
    display: inline-block;
  }
  /*
  .decorDetail{

    .tab-content{
      .decorDetailImageBorder{

        height: calc(100vh - 500px);
      }
    }
  }
  */
}

/* EOF Detailview */

/* Filter */
.decorFilter{
  .form-group{
    margin-bottom: .5rem;
    input,
    .filter-option-inner-inner{
      color: $red;
    }
    .btn-light {
      background-color: $white;
      border-color: $grey-lighten45;
    }
    .btn-light:not(:disabled):not(.disabled):active,
    .btn-light:not(:disabled):not(.disabled).active,
    .show > .btn-light.dropdown-toggle {
      background-color: $white;
      border-color: $grey-lighten45;
    }
    .btn-light:hover {
      background-color: $white;
      border-color: $grey-lighten45;
    }
  }
  #filter_expiry_state{
    border-top: 1px solid $border-color;
    padding-top: .5rem;
  }

  /* neue label Position */
  .form-group {
    position: relative;
  }
  /* versetzte Label erst einmal ausgeblendet*/
  .form-group > label {
    display: none;
    position: absolute;
    top: -11px;
    font-size: .8rem;
    z-index: 5;
    background: #fff;
    padding: 0px 4px;
    margin-left: 9px;
  }
  .col-form-label{
    display: none;
  }
}

/* BOF ReduceFilter */
.toogleFilter{
  display: none;
}
@media(max-width:767px){
  .toogleFilter{
    display: block;
  }

  .toogleFilter{
    overflow: hidden;
    margin-top: -8px;
    margin-bottom: 15px;
  }
  .toogleFilter > span{
    background: rgba($grey-lighten45,.3);
    border: 1px solid $grey-lighten45;
    border-top: none;
    padding: 0 15px;
    display: inline-block;
    -webkit-border-radius: 5px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: 5px;
    -moz-border-radius-topleft: 0;
    border-radius: 5px;
    border-top-left-radius: 0;
  }

  .toogleFilterContainer.open{
    height:auto;
    max-height:1200px;
  }
  .toogleFilterContainer{
    overflow:hidden;
    transition:max-height 0.9s ease-out;
    max-height:0;
  }
  .toogleFilterContainer.open + .toogleFilter{
    border-top: 1px solid $grey-lighten45;
    margin-top: 15px;
  }
}
/* BEF ReduceFilter */

/* Filter */
#frmFilter{
  margin-top:5px;
}

/* BOF Upload */
.fileUpload{
  cursor: pointer;
}

table.table-bordered tr.green {
  background-color:#d4edda;
}

table.table-bordered tr.yellow {
  background-color:#fff3cd;
}

table.table-bordered tr.red {
  background-color:#f8d7da;
}

/* EOF Upload */

/* Block UI*/
.blockOverlay{
  z-index: 1035 !important;
  background-color: rgb(170, 170, 170) !important;
}
.blockMsg{
  z-index: 1036 !important;
}


/* Businessunit Special */
.businessUnit-wilsonart{

}
.businessUnit-resopal{

}
.businessUnit-polyrey{
  .logoExtension {
      bottom: -1px;
  }
}

.hidden {
  display: none;
}

/* Loading */
.loading{
  .svg-inline--fa {
    height: 1.5em;
    width: 1.75em;
  }
  .fa-inverse {
    color:rgba($red,.05);
  }
  .fa-spin {
    color:$red;
  }
}
