/*
# **********************************************************************
#
#  (c) 2020 Tobias Fleckenstein <tfleckenstein@mp-group.net>, mp group GmbH
#
# **********************************************************************
# date/time    : 16.01.2020
# project      : Resopal Decors
# developer    : Tobias Fleckenstein
#
#   .-------------'```'----....,,__                        _,
#   |                               `'`'`'`'-.,.__        .'(
#   |                                             `'--._.'   )
#   |                                                   `'-.<
#   \               .-'`'-.                            -.    `\
#    \               -.o_.     _                     _,-'`\    |
#     ``````''--.._.-=-._    .'  \            _,,--'`      `-._(
#       (^^^^^^^^`___    '-. |    \  __,,..--'                 `
#        `````````   `'--..___\    |`
#                              `-.,'
# **********************************************************************
*/

$font-default: #626262;
$font-default-lighten10: lighten( $font-default, 10% );
$white: #ffffff;
$black: #000000;
$red: #e3001b;
$green: #206e20;

$grey: $font-default;
$grey-lighten45: lighten( $grey, 45% );
$grey-darken10: darken( $grey, 10% );
$placeholder: lighten( $grey, 20% );

$adminNavbarBg: $grey-lighten45;




// Überschreiben von BT CSS Variablen
// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, Roboto, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$body-bg: $white;
$body-color: $font-default;

$link-color: $red;
$link-hover-color: darken( $red, 10% );

$input-placeholder-color: $grey-lighten45;

/* Colors */
/*$primary:
$secondary:
*/
/* erfolg, new */
$success: rgb(78, 150, 8);
/* error, delete*/
$danger: rgb(227, 0, 27);
/* warnung, edit */
$warning: rgb(252, 134, 7);
/* info, anzeigen */
$info: rgb(0, 153, 214);
$light:$grey-lighten45;
$dark:$grey-darken10;


$navbar-top-height: 36px;
$siteHeaderHeight: 40px;

$nav-tabs-border-color: $grey-lighten45;
$input-border-color: #ced4da;
$secondary-color: #ced4da;
$border-color: $input-border-color;
$border-color-dark20: darken($input-border-color, 20%);